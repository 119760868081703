<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- Employee Profile -->
  <!-- ----------------------------------------------------------------------------- -->
  <b-card class="w-100 mb-4" no-body>
    <div class="p-35">
      <div class="d-flex align-items-start">
        <div>
          <h4 class="card-title mb-1">User Profile</h4>
          <b-card-text class="text-muted">
            To use add bg-light py-3 class in the every row styling.
          </b-card-text>
        </div>
      </div>
    </div>
    <hr class="m-0" />
    <b-card-body>
      <!-- part 1 start -->
      <h4 class="card-title">Course Info</h4>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Course Title </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="fname"
            type="text"
            v-model="form.title"
            placeholder="Course Title"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="lname"> Description </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="lname"
            type="text"
            v-model="form.description"
            placeholder="Short description"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="file"> Select Course Thumbnail </label>
        </b-col>
        <b-col sm="9">
          <b-form-file id="file"></b-form-file>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="interest"> Teacher </label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="form.user_id"
            :options="teachers"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="budget"> Level </label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="form.level_id"
            :options="levels"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="budget"> Subject </label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="form.category_id"
            :options="categories"
          ></b-form-select>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body>
      <!-- part 1 start -->
      <h4 class="card-title">Course Contents</h4>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col>
          <b-list-group>
            <b-list-group-item v-for="item in form.contents" :key="item.id">{{ item.title }} <b-button size="sm" pill variant="danger" @click="removeCourseItem(item.id)" class="px-1">Remove</b-button></b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Content Title </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="fname"
            v-model="courseItem.title"
            type="text"
            placeholder="Content Title"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="fname"> Course Details </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="fname"
            v-model="courseItem.description"
            type="text"
            placeholder="Content Details"
          ></b-form-input>
        </b-col>
      </b-row>
      <div class="p-35 border-top">
        <div class="btn-grp text-right">
          <b-button pill variant="primary" @click="addCourseItem()" class="px-4">Add</b-button>
        </div>
      </div>
    </b-card-body>
    <b-card-body class="bg-light">
      <h4 class="card-title">Course Settings</h4>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="lname"> Fee </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="email"
            v-model="form.fee"
            type="text"
            placeholder="Fee"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="contact"> Max Students </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="contact"
            v-model="form.config.max"
            type="text"
            placeholder="Max"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-3 border-bottom align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="company"> Joining After Start </label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="form.config.joining_after_start"
            :options="yesNoOptions"
          ></b-form-select>
        </b-col>
      </b-row>
      
      <b-row class="py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="aboutcomp"> Joining Without Fee </label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="form.config.joining_before_payment"
            :options="yesNoOptions"
          ></b-form-select>
        </b-col>
      </b-row>
    </b-card-body>  
    <b-card-body class="bg-light">
      <h4 class="card-title">Course Schedule</h4>
      <!-- part 3 start -->
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="date3"> Start Date </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="date3" type="date" v-model="form.start_date"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="date3"> End Date </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="date3" type="date" v-model="form.end_date"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="sdate3"> Start Time </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="sdate3" type="time" v-model="start_time"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="border-bottom py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="edate3"> End Time </label>
        </b-col>
        <b-col sm="9">
          <b-form-input id="edate3" type="time" v-model="end_time"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0" for="notes3"> WeekDays </label>
        </b-col>
        <b-col sm="9">
          <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
          <b-form-tags
            id="tags-component-select"
            v-model="form.schedule.weekdays"
            size="lg"
            class="mb-2"
            add-on-change
            no-outer-focus
          >
            <template
              v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
            >
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="info"
                    >{{ tag }}</b-form-tag
                  >
                </li>
              </ul>
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || availableOptions.length === 0"
                :options="availableOptions"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Choose a day...</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-col>
      </b-row>
      <!-- part 3 end -->
    </b-card-body>
    <div class="p-35 border-top">
      <div class="btn-grp text-right">
        <b-button pill variant="primary" @click="addNewCourse()" class="px-4">Save</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "AddCourse",
  data: () => ({
    start_time: null,
    end_time: null,
    courseItem:{
      title: null,
      description: null
    },
    form: {
      level_id: null,
      category_id: null,
      user_id: null,
      teacher: null,
      title: null,
      description: null,
      fee: null,
      rating: 5,
      config:{
        max: null,
        fee: null,
        joining_after_start: true,
        joining_before_payment: false
      },
      contents: [],
      schedule:{
        start: null,
        end: null,
        weekdays:[],
        timings:[800,1600],
      },
      status: 2,
      start_date: null,
      end_date: null
    },
    teachers: [],
    levels: [
      {
          value: 0,
          text: "A Levels"
      },
      {
          value: 1,
          text: "O Levels"
      },
      {
          value: 2,
          text: "Matric"
      },
      {
          value: 3,
          text: "FA Fsc ICom"
      },
      {
          value: 4,
          text: "Languages"
      },
      {
          value: 5,
          text: "IT Courses"
      }
    ],
    categories: [
      {
          value: 0,
          text: "Chemistry"
      },
      {
          value: 1,
          text: "Physics"
      },
      {
          value: 2,
          text: "Maths"
      },
    ],
    yesNoOptions: [
      {
        value: true,
        text: "Yes",
      },
      {
        value: false,
        text: "No",
      }
    ],
    weekdays: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getUsers","saveCourse"]),
    initialize() {
      this.getUsers().then(res => {
        this.teachers = res.filter(u => u.user_type === 1).map(t => {
          return {
            text: JSON.parse(t.profile).first_name+" "+JSON.parse(t.profile).last_name,
            value: t.user_id
          }
        });
      })
    },
    addNewCourse(){
      this.form.config.fee = this.form.fee;
      this.form.schedule.start = this.form.start_date;
      this.form.schedule.end = this.form.end_date;
      this.form.schedule.weekdays = this.form.schedule.weekdays.map((d, n) => {
        return {
          id: n,
          title: d
        }
      });
      this.form.schedule.timings[0] = parseInt(this.start_time.replace(/\D/g,''));
      this.form.schedule.timings[1] = parseInt(this.end_time.replace(/\D/g,''));
      this.form.contents = JSON.stringify(this.form.contents);
      this.form.schedule = JSON.stringify(this.form.schedule);
      this.form.config = JSON.stringify(this.form.config);
      this.form.teacher = this.teachers.find(t => t.value === this.form.user_id).text;
      this.saveCourse({course: this.form}).then(() => {
      this.$router.push({name: "Courses Table"});
        this.$bvToast.toast("New Course added", {
          title: 'Successfull Operation',
          autoHideDelay: 5000
        })
      })
    },
    addCourseItem() {
      this.form.contents.push(
        {
          id: Math.floor(Math.random() * 1000),
          title: this.courseItem.title,
          description: this.courseItem.description,
          status: 0
        }
      )
    },
    removeCourseItem(id) {
      const index = this.form.contents.indexOf(c => c.id === id)
      this.form.contents.splice(index, 1);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$refs["edit-modal"].show();
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.items.splice(index, 1);
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.editedItem.avatar = URL.createObjectURL(file);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Contact" : "Edit Contact";
    },
    rows() {
      return this.items.length;
    },
    availableOptions() {
      return this.weekdays.filter((opt) => this.form.schedule.weekdays.indexOf(opt) === -1);
    }
  },
  watch: {}
};
</script>